import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';
import _ from "lodash";

import callApi from "../callApi.js";
import { data as loanOfferData, tenure } from "./data.js";
import StepsTextForm from "./form.js";


const LoanOfferAction = ({
    loanOffer,
    handleModalClose,
    setButtonDisabled,
    updateLoanOfferTable
}) => {
    let data = _.cloneDeep(loanOffer);
    for (let key in loanOfferData) {
        if (!(key in data)) {
            data[key] = _.cloneDeep(loanOfferData[key])
        }
        else if (key === 'document_proof_of_income') {
            for (let subKey in loanOfferData[key]) {
                if (!(subKey in data[key])) {
                    data[key][subKey] = _.cloneDeep(loanOfferData[key][subKey])
                }
            }
        }
    }

    const [loanProviderIds, setLoanProviderIds] = useState([]);
    const [partnerIds, setPartnerIds] = useState([]);

    const getLoanProviderIds = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/loan_providers`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.loan_providers) {
                    let allLoanProviderIds = [];
                    for (let loan_provider in json.data.loan_providers) {
                        allLoanProviderIds.push({
                            value: loan_provider,
                            label: loan_provider
                        });
                    }
                    setLoanProviderIds(allLoanProviderIds);
                }
            })
            .catch((err) => err);
    };

    const getPartnerIds = () => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/partners`;
        callApi(url)
            .then((json) => {
                if (json.data && json.data.partners) {
                    let allPartnerIds = [];
                    for (let partner in json.data.partners) {
                        allPartnerIds.push({
                            value: partner,
                            label: partner
                        });
                    }
                    setPartnerIds(allPartnerIds);
                }
            })
            .catch((err) => err);
    };

    useEffect(() => {
        if (loanProviderIds.length === 0) {
            getLoanProviderIds();
        }
    }, [loanProviderIds]);

    useEffect(() => {
        if (partnerIds.length === 0) {
            getPartnerIds();
        }
    }, [partnerIds]);

    const loan_offer_number_fields = [
        "min_amount", "max_amount", "processing_fee_amount",
        "minimum_income.professional", "minimum_income.salaried", "minimum_income.self_employed",
        "documentary_stamp_tax.amount", 'documentary_stamp_tax.loan_block',
        "featured_position", "pre_termination_fee.amount", "pre_termination_fee.percentage",
        // tenure annual rate, monthly rate and months fields managed separately
    ];

    const loan_offer_number_field_names = [
        "min_amount", "max_amount", "processing_fee_amount",
        "professional", "salaried", "self_employed", "amount", "loan_block", 
        "annual_rate", "monthly_rate", "months", "featured_position", "percentage",
    ];

    const dataTransformations = (data) => {
        let result = {};
        for (let key in data) {
            if ((typeof(data[key]) == "string" && 
                loan_offer_number_field_names.includes(key) &&
                data[key] !== "") || (typeof(data[key]) == "number" && 
                loan_offer_number_field_names.includes(key))) {
                    result[key] = parseFloat(data[key]);
            }
            else if(typeof(data[key]) == "string" && data[key] !== "") {
                result[key] = data[key];
            }
            else if(_.isArray(data[key]) && data[key].length > 0) {
                let items = [];
                for (let value of data[key]) {
                    if (typeof(value) == "string" && value !== "") {
                        items.push(value);
                    }
                    else if (value && typeof(value) == "object") {
                        let object_result = dataTransformations(value);
                        if (!_.isEmpty(object_result)) {
                            items.push(object_result);
                        }
                    }
                }
                if(items.length > 0) {
                    result[key] = items;
                }
            }
            else if(data[key] && typeof(data[key]) == "object") {
                let object_result = dataTransformations(data[key]);
                if (!_.isEmpty(object_result)) {
                    result[key] = object_result;
                }
            }
            else if(typeof(data[key]) == "boolean") {
                result[key] = data[key];
            }
        }
        return result;
    };

    const updateLoanOffer = (loan_offer, setSubmitting) => {
        let url = `${process.env.REACT_APP_API_URL}/v1/providers/loan_offers`;
        callApi(url, 'PUT', {'loan_offers': [loan_offer]})
            .then((json) => {
                setSubmitting(false);
                setButtonDisabled(false);
                handleModalClose();
                updateLoanOfferTable();
            })
            .catch((err) => err);
    };

    return (
        <div className="merchant-offer-page">
            <Formik
                initialValues={data}
                validate={values => {
                    const isNumberRegex = /^-?\d*\.?\d*$/;
                    const errors = {};
                    if (!values.loan_provider_id) {
                        errors.loan_provider_id = 'Required';
                    }
                    for (let value of [
                        'page_url', 'tnc_url'
                    ]) {
                        if (_.get(values, value) && !_.get(values, value).startsWith('https://')) {
                            _.set(errors, value, 'Invalid URL');
                        }
                    }
                    for (let value of loan_offer_number_fields) {
                        if (_.get(values, value) && !(isNumberRegex.test(_.get(values, value)))) {
                            _.set(errors, value, "Invalid Number")
                        }
                    }
                    if (values.tenures) {
                        values.tenures.map((tenure, index) => {
                            if (tenure.annual_rate && !(isNumberRegex.test(tenure.annual_rate))) {
                                _.set(errors, `tenures.${index}.annual_rate`, "Invalid Number")
                            }
                            if (tenure.monthly_rate && !(isNumberRegex.test(tenure.monthly_rate))) {
                                _.set(errors, `tenures.${index}.monthly_rate`, "Invalid Number")
                            }
                            if (tenure.months && !(isNumberRegex.test(tenure.months))) {
                                _.set(errors, `tenures.${index}.months`, "Invalid Number")
                            }
                        })
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    setButtonDisabled(true);
                    let loan_offer = dataTransformations(values);
                    updateLoanOffer(loan_offer, setSubmitting);
                }}
                >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form id="loan-offer-edit">
                        <h4>
                            Basic Details
                        </h4>
                        <div className="row">
                            <label className="col-md-2">ID:*</label>
                            <Field 
                                className="col-md-8" 
                                readOnly type="text" name="id" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Loan Provider Id:*</label>
                            <Select
                                className="col-md-8"
                                name="loan_provider_id"
                                options={loanProviderIds}
                                defaultValue={{
                                    value: values.loan_provider_id,
                                    label: values.loan_provider_id
                                }}
                                onChange={(selected) => setFieldValue("loan_provider_id", selected.value)} />
                            <ErrorMessage 
                                className="text-danger" 
                                name="loan_provider_id" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Name:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="name" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Featured Position:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="featured_position" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="featured_position" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Currency:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="currency" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Page URL:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="page_url" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="page_url" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">TnC URL:</label>
                            <Field 
                                className="col-md-8" 
                                type="text" name="tnc_url" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="tnc_url" component="div" />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Application Process
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Application Process:</label>
                            <StepsTextForm namePrefix={`application_process`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Benefits
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Benefits:</label>
                            <StepsTextForm namePrefix={`benefits`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Qualifications
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Qualifications:</label>
                            <StepsTextForm namePrefix={`qualifications`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Submit Requirements
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Submit Requirements:</label>
                            <StepsTextForm namePrefix={`submit_requirements`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Documents Proof Of Identity
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">
                                Documents Proof Of Identity:
                            </label>
                            <StepsTextForm namePrefix={`documents_proof_of_identity`} />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Document Proof Of Income
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">
                                Document Proof Of Income:
                            </label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Cardholder:</label>
                                <StepsTextForm namePrefix={`document_proof_of_income.cardholder`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">OFW:</label>
                                <StepsTextForm namePrefix={`document_proof_of_income.ofw`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Salaried:</label>
                                <StepsTextForm namePrefix={`document_proof_of_income.salaried`} />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Self Employed:</label>
                                <StepsTextForm namePrefix={`document_proof_of_income.self_employed`} />
                            </div>
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Documentary Stamp Tax
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Documentary Stamp Tax:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="documentary_stamp_tax.amount" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="documentary_stamp_tax.amount" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="documentary_stamp_tax.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Loan Block:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="documentary_stamp_tax.loan_block" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="documentary_stamp_tax.loan_block" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Tenures
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Tenures:</label>
                            <FieldArray
                                className="col-md-8"
                                name="tenures"
                                render={arrayHelpers => (
                                <div>
                                    {values.tenures && values.tenures.length > 0 ? (
                                    values.tenures.map((item, index) => (
                                        <div key={index}>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Annual Rate:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`tenures.${index}.annual_rate`}
                                                    onChange={(e) => {
                                                        const annualRate = parseFloat(e.target.value) || 0;
                                                        const calculateMonthlyRate = (annualRate) => {
                                                            return (Math.round(annualRate / 12 * 100) / 100).toString();
                                                        };
                                                        setFieldValue(`tenures.${index}.annual_rate`, e.target.value);
                                                        setFieldValue(`tenures.${index}.monthly_rate`, calculateMonthlyRate(annualRate));
                                                    }} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`tenures.${index}.annual_rate`} component="div" />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Monthly Rate:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`tenures.${index}.monthly_rate`} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`tenures.${index}.monthly_rate`} component="div" />
                                            </div>
                                            <div className="row mt-40 px-5">
                                                <label className="col-md-2">Months:</label>
                                                <Field 
                                                    className="col-md-8"
                                                    type="text" name={`tenures.${index}.months`} />
                                                <ErrorMessage 
                                                    className="text-danger" 
                                                    name={`tenures.${index}.months`} component="div" />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                -
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.push(_.cloneDeep(tenure))}
                                            >
                                                +
                                            </button>
                                        </div>
                                    ))
                                    ) : (
                                    <button type="button" onClick={() => arrayHelpers.push(_.cloneDeep(tenure))}>
                                        Add Tenure
                                    </button>
                                    )}
                                </div>
                                )}
                            />
                        </div>
                        <div className="pt-4">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Minimum Income
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Minimum Income:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Professional:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="minimum_income.professional" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="minimum_income.professional" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Salaried:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="minimum_income.salaried" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="minimum_income.salaried" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Self Employed:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="minimum_income.self_employed" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="minimum_income.self_employed" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Pre Termination Fee
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Pre Termination Fee:</label>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Amount:</label>
                                <Field
                                    className="col-md-8"
                                    type="text" name="pre_termination_fee.amount" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="pre_termination_fee.amount" component="div" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Description:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="pre_termination_fee.description" />
                            </div>
                            <div className="row mt-40 px-5">
                                <label className="col-md-2">Percentage:</label>
                                <Field 
                                    className="col-md-8"
                                    type="text" name="pre_termination_fee.percentage" />
                                <ErrorMessage 
                                    className="text-danger" 
                                    name="pre_termination_fee.percentage" component="div" />
                            </div>
                        </div>
                        <div className="pt-2">
                            <hr className="border-2 border-dark" />                        
                        </div>

                        <h4>
                            Other Details
                        </h4>
                        <div className="row mt-40">
                            <label className="col-md-2">Min Amount:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="min_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="min_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Max Amount:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="max_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="max_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Penalty Fee:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="penalty_fee" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="penalty_fee" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Processing Fee Amount:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="processing_fee_amount" />
                            <ErrorMessage 
                                className="text-danger" 
                                name="processing_fee_amount" component="div" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Processing Fee Description:</label>
                            <Field 
                                className="col-md-8"
                                type="text" name="processing_fee_description" />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Partner:</label>
                            <Select
                                className="col-md-8"
                                name="partner"
                                options={partnerIds}
                                defaultValue={{
                                    value: values.partner,
                                    label: values.partner
                                }}
                                onChange={(selected) => setFieldValue("partner", selected.value)} />
                        </div>
                        <div className="row mt-40">
                            <label className="col-md-2">Tooltip:</label>
                            <Field 
                                className="col-md-8"
                                as="textarea"
                                type="text" name="tooltip" />
                        </div>
                        {/* <div className="index-container">
                            <button 
                                className="index-button"
                                type="submit" disabled={isSubmitting}>
                                    Save
                            </button>
                        </div> */}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default LoanOfferAction;