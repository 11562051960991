import _ from "lodash";


export const loan_offer_text = {
    text: ""
}


export const loan_offer_step_text = {
    steps: [_.cloneDeep({
        steps: [],
        text: ""
    })],
    text: ""
}


export const tenure = {
    annual_rate: "",
    monthly_rate: "",
    months: ""
}


export const data = {
    loan_provider_id: "",
    name: "",
    featured_position: "",
    app_featured: false,
    display_order: "",
    currency: "",
    page_url: "",
    tnc_url: "",
    application_process: [_.cloneDeep(loan_offer_step_text)],
    benefits: [_.cloneDeep(loan_offer_step_text)],
    qualifications: [_.cloneDeep(loan_offer_step_text)],
    submit_requirements: [_.cloneDeep(loan_offer_step_text)],
    documents_proof_of_identity: [_.cloneDeep(loan_offer_step_text)],
    document_proof_of_income: {
        cardholder: [_.cloneDeep(loan_offer_step_text)],
        ofw: [_.cloneDeep(loan_offer_step_text)],
        salaried: [_.cloneDeep(loan_offer_step_text)],
        self_employed: [_.cloneDeep(loan_offer_step_text)]
    },
    documentary_stamp_tax: {
        amount: "",
        description: "",
        loan_block: ""
    },
    tenures: [_.cloneDeep(tenure)],
    minimum_income: {
        professional: "",
        salaried: "",
        self_employed: ""
    },
    pre_termination_fee: {
        amount: "",
        description: "",
        percentage: ""
    },
    min_amount: "",
    max_amount: "",
    penalty_fee: "",
    processing_fee_amount: "",
    processing_fee_description: "",
    partner: "",
    tooltip: "The interest rate shown is a published rate for reference only. Your actual rate will be based on your credit profile and other factors. It does not represent the Effective Interest Rate (EIR), which accounts for fees and repayment terms. Final rates are at the bank's discretion and will be confirmed during your application."
}